:root {
    --primary-color-brand: #005CB9;
    --primary-color-black: #444444;
    --primary-color-gray: #D9D9D9;
    --primary-color-light-gray: #F5F5F5;
    --primary-color-dark-gray: #707070;
    --padding-top-forms: 100px;
    --font--regular: FuturaLT;
    --font--bold: FuturaLT-Bold;
    --font--light: FuturaLT-Light;
    --font--heavy: FuturaLT-Heavy;
    --font--book: FuturaLT-Book;
}

/* start fonts */
@font-face {
    font-family: var(--font--bold);
    src: url('./Resources/Fonts/CenturyGothicBold.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: var(--font--regular);
    src: url('./Resources/Fonts/CenturyGothic.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Century-Italic;
    src: url('./Resources/Fonts/CenturyGothicItalic.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: FuturaLT;
    src: url('./Resources/Fonts/futura-lt/FuturaLT.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: FuturaLT-Bold;
    src: url('./Resources/Fonts/futura-lt/FuturaLT-Bold.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: FuturaLT-Light;
    src: url('./Resources/Fonts/futura-lt/FuturaLT-Light.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: FuturaLT-Heavy;
    src: url('./Resources/Fonts/futura-lt/FuturaLT-Heavy.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: FuturaLT-Book;
    src: url('./Resources/Fonts/futura-lt/FuturaLT-Book.ttf') format("truetype");
    font-display: swap;
}

/* end fonts */
/* start texts */
.title__information {
    font-family: var(--font--heavy);
    color: var(--primary-color-dark-gray);
}

.title__primary {
    font-family: var(--font--bold);
    color: var(--primary-color-brand);
}

.title__secondary {
    font-family: var(--font--bold);
    color: #707070;
}

.subtitle__primary {
    font-family: var(--font--regular);
    color: var(--primary-color-brand);
}

.subtitle__secondary {
    font-family: var(--font--regular);
    color: white;
}

.paragraph__primary {
    font-family: var(--font--regular);
    color: var(--primary-color-black);
}

.paragraph__secondary {
    font-family: var(--font--regular);
    color: white;
}

/* end texts */
/* start inputs */
.regular__label {
    font-family: var(--font--bold);
    color: var(--primary-color-black);
}

.form-select {
    background-image: url('./Resources/Images/Icons/icon_triangle.svg') !important;
}

/* end inputs */
/* start backgrounds */
.background__gray--light {
    background-color: var(--primary-color-light-gray);
}

.background__black {
    background-color: #2D3A3F;
}

.background__gray {
    background-color: var(--primary-color-gray);
}

.background__fuccia {
    background-color: var(--primary-color-brand);
}

/* end backgrounds */
/* start accordion */
.accordion-header .accordion-button:not(.collapsed) {
    background-color: #f3f3f3;
    color: #444444;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-weight: bold;
}

.downloadPdfButton {
    color: var(--primary-color-brand);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--primary-color-brand);
    cursor: pointer;
    text-align: center;
    max-width: 200px;
}

.text-warning-custom {
    color: #ffc107 !important;
}

.text-success-custom {
    color: #28a745 !important;
}

.text-error-custom {
    color: #dc3545 !important;
}

.tituloControlEmision {
    font-size: 16px;
    font-family: var(--font--regular);
    text-align: center;
    padding-bottom: 35px;
    color: #2D3A3F;
}

.form-check-input:checked {
    background-color: #ce0f69;
    border-color: #ce0f69;
}

/* end accordion */
@media (min-width:320px) {
    .title__card__home {
        font-size: 16px;
        line-height: 18px;
    }

    .title__information {
        font-size: 18px;
    }
}

@media (min-width:768px) {
    .title__card__home {
        font-size: 24px;
        line-height: 26px;
    }

    .title__information {
        font-size: 32px;
    }
}

@media (min-width:1024px) {
    .title__card__home {
        font-size: 22px;
        line-height: 30px;
    }
}

table th p, table td p {
    width: max-content;
    margin: 0;
}
